@import '/src/styles/utilities.scss';

.showComicViewer {
  margin-bottom: $space-36;

  @include media-breakpoint-between(md, lg) {
    margin-bottom: $space-40;
  }

  @include media-breakpoint-up(lg) {
    @include create-container();

    margin-bottom: $space-52;
  }
}

.showComicViewer__adContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: $space-20;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr $width-ad-rail;
  }
}
