@import '/src/styles/utilities.scss';

.showFiveFavorites {
  background-color: var(--color-blue-100);
}

.showFiveFavorites__container {
  @include create-container();
  @include media-breakpoint-up(lg) {
    display: flex;
    gap: $space-16;
    padding: $space-40 0;
  }
}

.showFiveFavorites__railAd {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    margin-top: $space-96;
  }
}

.showFiveFavorites__ad {
  padding-top: $space-36;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
